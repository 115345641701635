import type { ContentBlockQuery } from '#build/graphql-operations'

export default async function (uuid: string) {
  return await useAsyncGraphqlQuery('contentBlock', { uuid }, {
    transform: function (v) {
      const data = v.data as ContentBlockQuery
      return data.contentBlock.content
    }
  })
}
